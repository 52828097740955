import '../../../styles/HeroQuotes.css';

export default function HeroFooterQuotes() {
  const quoteJSX = (text: string, index: number) => {
    return (
      <div className={`hero_footer_quote quote_${index}`}>
        <p>{text}</p>
      </div>
    );
  };

  return (
    <div className="hero_footer_quotes">
      {quoteJSX(
        '"SeductiveSnapAI gives you control over your adult content, generating sultry, professional-quality images and videos in an instant, leaving traditional photo shoots in the dust."',
        1
      )}
      {quoteJSX(
        '"With SeductiveSnapAI, you can unleash your inner creativity and produce an endless variety of explicit content, without the need for expensive photo shoots or models. Your fantasies, brought to life."',
        2
      )}
      {quoteJSX(
        '"SeductiveSnapAI\'s cutting-edge technology enables you to build a personalized adult portfolio, with an infinite range of accessories and poses at your fingertips. Unleash your inner erotica and make your fantasies an ultimate reality"',
        3
      )}
    </div>
  );
}
