import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface ProtectedRouteProps {
  children: ReactNode;
  isAuthenticated: boolean;
  isLoading: boolean;
}

export default function ProtectedRoute({
  children,
  isAuthenticated,
  isLoading,
}: ProtectedRouteProps) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      navigate('/');
    }
  }, [isLoading, isAuthenticated, navigate]);

  if (isLoading) {
    return <></>;
  }

  return isAuthenticated ? <>{children}</> : null;
}
