import '../../../styles/Features.css';

export default function StudioFeature() {
  return (
    <div className="feature">
      <h2 id="studio" style={{ textAlign: 'center' }}>
        The first fully equipped AI photo studio
      </h2>
      <p style={{ textAlign: 'center' }}>
        Stop messing around with AI image generators with broken faces, low resemblance and
        inconsistent characters.
      </p>
      <br />
      <p style={{ textAlign: 'center' }}>
        Start producing high quality AI photographs instantly with SeductiveSnapAI with consistent
        characters.
      </p>
      <br />
      <a href="">
        <img
          style={{
            display: 'block',
            margin: '14px auto',
            maxWidth: '1000px',
            width: '100%',
            boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)',
            border: '1px solid var(--global-border-color)',
            borderRadius: '12px',
          }}
          src="/homeImages/dashboard.png"
        />
      </a>
    </div>
  );
}
