import { signOut } from 'firebase/auth';
import Header from '../components/Home/Header';
import { auth } from '../firebase';
import Pricing from '../components/Home/Pricing/Pricing';
import FAQ from '../components/Home/FAQ/FAQ';
import Testimonials from '../components/Home/Features/Testimonials';
import '../styles/PricingPage.css';
import { pricingFaqItems } from '../data/faq';
import DocumentTitle from '../utils/DocumentTitle';
import { useDashboard } from '../components/Dashboard/DashboardContext';
import { useNavigate } from 'react-router-dom';

interface PricingPageProps {
  title?: string;
}

export default function PricingPage({ title }: PricingPageProps) {
  const { authState } = useDashboard();
  const navigate = useNavigate();

  const handleHomeClick = async () => {
    if (authState.user?.user.subscription) {
      navigate('/dashboard');
    } else {
      try {
        await signOut(auth);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <div>
      <DocumentTitle title={title || 'DreamSnapAI'} />
      <Header titleClick={handleHomeClick} />
      <Pricing manageSubscription={authState.user?.user.customerId ? true : false} />
      <FAQ faqItems={pricingFaqItems} />
      <Testimonials />
      <p className="div_stripe_modal_disclaimer">
        By clicking the button above to proceed you agree with DreamSnapAI's{' '}
        <a href="/legal">TOS</a>. DreamSnapAI provides you immediate access to digital content as
        soon as you complete your purchase, without waiting the 14-day withdrawal period. Therefore,
        you expressly waive your right to withdraw from this purchase. Due to the high costs of GPU
        processing, we're not able to offer refunds because we reserve servers and incur high costs
        for your usage immediately. The subscription will be automatically renewed for the same
        period of time after the agreed term. If you do not wish to renew your subscription in the
        end of the term, you should cancel prior to the renewal date of your subscription.
      </p>
    </div>
  );
}
