import '../../../styles/Testimonials.css';

const VerifiedPurchase = () => (
  <span
    style={{
      marginTop: '7px',
      float: 'right',
      opacity: 0.5,
      filter: 'saturate(0)',
      fontSize: '10px',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    }}
  >
    ✅ Verified purchase
  </span>
);

interface TestimonialProps {
  stars: string;
  content: string;
  name: string;
}

const Testimonial = ({ stars, content, name }: TestimonialProps) => (
  <div className="testimonial">
    <div className="stars">{stars}</div>
    <p>{content}</p>
    <br />
    <p>
      <strong>{name}</strong>
      <VerifiedPurchase />
    </p>
  </div>
);

export default function Testimonials() {
  return (
    <div id="testimonials" className="testimonials feature">
      <h2 style={{ textAlign: 'center' }}>
        Users are thrilled with their AI-generated masterpieces
      </h2>

      <Testimonial
        stars="⭐️⭐️⭐️⭐️⭐️"
        content="I'm utterly seduced by SeductiveSnapAI! As a content creator, it's transformed the way I bring my adult fantasies to life. The instant generation of teases and temptations has heightened my creativity and speed, giving me a competitive edge. It's now an essential tool in my arsenal, and I can't imagine working without it! 😏"
        name="Alex"
      />

      <Testimonial
        stars="⭐️⭐️⭐️⭐️⭐️"
        content="I'm completely enamored by SeductiveSnapAI's limitless potential! I've crafted sizzling intimate moments, tantalizing poses, and seductive scenarios that effortlessly bring my desires to the forefront. The ability to control every detail, from lighting to wardrobe, has elevated my content to a new level. The possibilities are truly boundless, and I'm obsessed with harnessing its power to satisfy my desires! 🔥"
        name="Sophia"
      />

      <Testimonial
        stars={'⭐️⭐️⭐️⭐️'}
        content="SeductiveSnapAI has swept me off my feet! The user-friendly interface made it an absolute breeze to dive in and start crafting sultry, high-quality content. I've unleashed a torrent of creative passion, from sultry selfies to steamy scenarios, and SeductiveSnapAI consistently delivers breathtaking results. It's an irresistible blast of fun and an explosive force of creativity! 😉"
        name="Marco"
      />
    </div>
  );
}
