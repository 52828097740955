import { ReactNode, useEffect, useState } from 'react';
import '../../../styles/Hero.css';
import starSvg from '../../../assets/star.svg';
import laurelSvg from '../../../assets/laurel.svg';
import { getAuth, GoogleAuthProvider, sendSignInLinkToEmail, signInWithPopup } from 'firebase/auth';
import { useSnackbar } from '../../SnackbarContext';
import LoadingSpinner from '../../Dashboard/MainContent/LoadingSpinner';
import TurnstileWrapper from './Turnstile';

interface HeroWidthContainerProps {
  title: string | undefined;
  description: ReactNode | undefined;
  showLogin: boolean;
}

export default function HeroWidthContainer({
  title,
  description,
  showLogin,
}: HeroWidthContainerProps) {
  const [email, setEmail] = useState<string>('');
  const [isLinkSent, setIsLinkSent] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const { showSnackbar } = useSnackbar();
  const auth = getAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [turnstileToken, setTurnstileToken] = useState<string | null>(null);

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };

  const handleTurnstileVerify = (token: string) => {
    setTurnstileToken(token);
  };

  const handleTurnstileError = () => {
    console.log('An error occurred. Please try again.');
  };

  const handleTurnstileExpire = () => {
    setTurnstileToken(null);
    showSnackbar('Verification expired. Please try again.', 'error');
  };

  const handleGoogleSignIn = async () => {
    setIsLoading(true);

    if (!turnstileToken) {
      showSnackbar('Please complete the verification', 'error');
      setIsLoading(false);
      return;
    }

    try {
      const response = { success: true };

      if (response.success) {
        // Handle google auth
        const provider = new GoogleAuthProvider();
        try {
          await signInWithPopup(auth, provider);
        } catch {
          showSnackbar('Error signing in with Google', 'error');
        }
      } else {
        showSnackbar('Turnstile verification failed. Please try again.', 'error');
      }
    } catch {
      showSnackbar('An error occurred. Please try again.', 'error');
    }
    setIsLoading(false);
  };

  const handleButtonClick = async () => {
    if (isValidEmail(email)) {
      if (!turnstileToken) {
        showSnackbar('Please complete the Turnstile challenge', 'error');
        return;
      }

      try {
        const response = { success: true };

        if (response.success) {
          handleSendLink();
          showSnackbar('Email confirmed! 🎉 Please check your email', 'success');
          setIsLinkSent(true);
        } else {
          showSnackbar('Turnstile verification failed. Please try again.', 'error');
        }
      } catch {
        showSnackbar('An error occurred. Please try again.', 'error');
      }
    } else {
      showSnackbar('Please enter a valid email', 'error');
    }
  };

  const handleSendLink = () => {
    const actionCodeSettings = {
      url: window.location.href,
      handleCodeInApp: true,
    };

    sendSignInLinkToEmail(auth, email, actionCodeSettings).then(() => {
      window.localStorage.setItem('emailForSignIn', email);
      setIsLinkSent(true);
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const targetDiv = document.getElementById('features');
      if (targetDiv) {
        const targetPosition = targetDiv.getBoundingClientRect().top;
        const scrollPosition = window.scrollY;

        if (scrollPosition > targetPosition) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="hero-width-container">
      <div className="text">
        <div className="laurel">
          <span className="laurel-text"> #1 AI Porn App </span>
          <span className="laurel-stars">
            <img src={starSvg} width={13} height={13} />
            <img src={starSvg} width={13} height={13} />
            <img src={starSvg} width={13} height={13} />
            <img src={starSvg} width={13} height={13} />
            <img src={starSvg} width={13} height={13} />
          </span>
          <img src={laurelSvg} width={185} height={58} className="laurel-img" />
        </div>
        <br />
        <h1 className="h1">{title ? title : '🔥 Create Realistic AI Porn'}</h1>
        <br />

        {description ? (
          description
        ) : (
          <h3 className="h3">
            <strong>
              Say goodbye to low-quality fakes and hello to ultra-realistic AI-generated porn!{' '}
            </strong>
            Our advanced AI technology allows you to create stunning, lifelike nude photos and
            videos using only a few selfies. From sensual poses to explicit scenes, let our AI magic
            bring your fantasy to life and satisfy your cravings.
            <br />
            <div className="benefit">
              ✏️ <a href="#selfies">Upload selfies</a> and create your own{' '}
              <a href="#model">AI model</a>
            </div>
            <div className="benefit">
              📸 <a href="#selfies">Take 100% AI photos</a> and videos in any pose, place, or scene,
              scene, as explicit as your imagination allows
            </div>
            <div className="benefit">
              🚀 <a href="#selfies">Give your fantasies an extra boost</a> with our built-in scene
              suggestions, designed to spark your imagination and creativity.
            </div>
          </h3>
        )}
      </div>
      {showLogin && (
        <div className={`cta-box shaded_border ${isScrolled ? 'floating' : ''}`}>
          <input
            type="email"
            className="input_confirm_email"
            placeholder="Type your email..."
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <TurnstileWrapper
            onVerify={handleTurnstileVerify}
            onError={handleTurnstileError}
            onExpire={handleTurnstileExpire}
          />
          <button
            className={`button action-confirm-email ${isLinkSent ? 'disabled' : ''}`}
            onClick={handleButtonClick}
            disabled={isLinkSent}
          >
            {'Create your AI model +'}
          </button>
          <div className="or-wrap">
            <div className="or-line" />
            <div className="or-text">or</div>
            <div className="or-line" />
          </div>
          <a>
            <span
              className={`button action-login-with-google ${isLoading ? 'disabled' : ''}`}
              style={{ marginBottom: '14px' }}
              onClick={handleGoogleSignIn}
            >
              {isLoading && (
                <div style={{ margin: 'auto', height: 22, width: 22 }}>
                  <LoadingSpinner size={20} />
                </div>
              )}
              {!isLoading && (
                <>
                  <svg
                    style={{ verticalAlign: 'middle' }}
                    width="22"
                    height="22"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_229_5144)">
                      <path
                        d="M23.766 12.5477C23.766 11.732 23.6999 10.9119 23.5588 10.1094H12.24V14.7304H18.7217C18.4528 16.2207 17.5885 17.5391 16.323 18.3769V21.3752H20.19C22.4608 19.2852 23.766 16.1987 23.766 12.5477Z"
                        fill="#4285F4"
                      ></path>
                      <path
                        d="M12.24 24.2708C15.4764 24.2708 18.2058 23.2082 20.1944 21.3739L16.3274 18.3756C15.2516 19.1075 13.8626 19.522 12.2444 19.522C9.11376 19.522 6.45934 17.4099 5.50693 14.5703H1.51648V17.6613C3.55359 21.7134 7.70278 24.2708 12.24 24.2708Z"
                        fill="#34A853"
                      ></path>
                      <path
                        d="M5.50253 14.5683C4.99987 13.0779 4.99987 11.4641 5.50253 9.97375V6.88281H1.51649C-0.18551 10.2736 -0.18551 14.2684 1.51649 17.6592L5.50253 14.5683Z"
                        fill="#FBBC04"
                      ></path>
                      <path
                        d="M12.24 5.01919C13.9508 4.99274 15.6043 5.6365 16.8433 6.8182L20.2694 3.39215C18.1 1.35504 15.2207 0.235065 12.24 0.27034C7.70277 0.27034 3.55359 2.82775 1.51648 6.88434L5.50252 9.97528C6.45052 7.13126 9.10935 5.01919 12.24 5.01919Z"
                        fill="#EA4335"
                      ></path>
                    </g>
                    <defs>
                      <clipPath id="clip0_229_5144">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="translate(0 0.269531)"
                        ></rect>
                      </clipPath>
                    </defs>
                  </svg>
                  <span style={{ verticalAlign: 'middle' }}>Continue with Google</span>
                </>
              )}
            </span>
          </a>
          <p
            className="already_have_account_text"
            style={{ fontSize: '11px', textAlign: 'center', marginTop: '-10px', opacity: '0.75' }}
          >
            If you already have an account, we'll log you in
          </p>
        </div>
      )}
    </div>
  );
}
