import { FAQItem } from '../components/Home/FAQ/FAQ';

export const homeFaqItems: FAQItem[] = [
  {
    question: "How does SeductiveSnapAI's Photo Generator work?",
    answer:
      "SeductiveSnapAI lets you upload selfies, create AI models and then generate AI photos with them. We teach the AI how you look and then it's able to generate photorealistic images of you. You can put yourself in different settings, with different outfits, doing different actions, with different expressions. And best of all, you can do all this from your laptop or phone without having to pay an expensive photographer $100s or $1000s.<br><br>After sign up you get access to SeductiveSnapAI's studio which lets you use our photo shoot preset templates, write prompts or copycat photos of other people, to generate anything you can think of.",
  },
  {
    question: 'What AI model do you use?',
    answer: 'SeductiveSnapAI uses our own AI generation pipeline over Flux.',
  },
  {
    question: 'Can I create AI influencers with SeductiveSnapAI?',
    answer:
      'Yes! You can create AI influencers by creating a model in the Create a model tab with 10+ images ( preferably 20+ ) of a person and once it has completed you will be able to either create your own prompt or select from the available prompts to start creating AI images.',
  },
  {
    question: 'How much does SeductiveSnapAI cost?',
    answer:
      'You can check current pricing <a href="/#pricing">here</a>. You get 6+ months free if you pay yearly. You can cancel at any time.',
  },
  {
    question: 'My payment card is not accepted when signing up?',
    answer:
      "Stripe handles all payments and we don't have control over which cards are accepted. If your card is not accepted, please try another card or contact your bank.",
  },
  {
    question: 'What type of photos should I upload for creating an AI character?',
    answer:
      'We recommend uploading photos with high variety, a mix of close-up selfies and full body shots in a variety of places, angles, clothes and expressions. Do not upload photos with low variety, group photos, other people, sunglasses, hats, photos where your face is cut off or not visible.',
  },
  {
    question: 'What file formats of photos do you accept for creating an AI character?',
    answer:
      "We accept JPG, PNG, WebP and AVIF files only. HEIC is not supported now, so you'll have to convert those to JPG first",
  },
  {
    question: 'How long will it take to create my AI character?',
    answer:
      'It generally takes about 10 minutes but can be less than 5 minutes, depending on the number of photos you upload.',
  },
  {
    question: 'How long will it take to take an AI photo?',
    answer:
      'On average, it takes about 10 seconds to take an AI photo, but you can take multiple photos simulateously.',
  },
  {
    question: 'What will you do with my AI character photos?',
    answer:
      'We only use your photos to create an AI character. Your data privacy and security are our top priorities.',
  },
  {
    question: 'How much will the photos look like me?',
    answer:
      'The resemblance of the photos to you depends on the quality and variety of the photos you upload. The better and more varied your photos, the more accurately the AI can understand and represent your unique characteristics. We keep improving our models every day.',
  },
  {
    question: 'Will the photos have AI artefacts?',
    answer:
      "Sometimes yes. Depending on the quality of the uploaded photos, and the type of photos you take, you might see some AI artefacts. Any app that uses generative AI imaging will have artefacts. They can be illogical things like extra arms, deformed faces etc. From our experience, about 75% of photos you take will be good enough, and 10% will be exceptionally good. We're improving SeductiveSnapAI every day to reduce artefacts for example by auto-detecting them and then re-trying. But remember it's not perfect yet and we're not promising it is!",
  },
  {
    question: 'Can I upload or generate photos of kids or minors?',
    answer:
      "No, it is strictly prohibited to upload or generate photos of kids or minors. Our service is designed for legal adults (18+) only. Additionally, AI results can be unpredictable with children, so it's best to refrain from doing so.",
  },
  {
    question: 'How do I get 6+ months free when signing up?',
    answer:
      'If you sign up for the yearly plan, the pricing is over 50% cheaper, which means 6+ months free compared to regular monthly pricing. Make sure to select yearly and you are saving a lot of money! This is our way of thanking you for your trust in our SeductiveSnapAI and lets us reinvest your money to improve the product.',
  },
  {
    question: 'Do I get access after paying?',
    answer:
      'Yes, immediately after payment you get an automated email with a login link. You can then create your first AI model and start taking AI photos. Creating a model usually takes ~30 minutes and then taking a photo takes ~30 seconds.',
  },
  {
    question: 'Is the payment service secure?',
    answer:
      'Yes, our payment service is secure. We use Stripe for financial processing, and we do not store any information about your card. Stripe ensures bank-level security standards.',
  },
  {
    question: 'Can I get a receipt or invoice?',
    answer: 'Yes, after payment you get a receipt and invoice.',
  },
  {
    question: 'Is my data secure? Do my photos and models remain private?',
    answer:
      'Yes! The models you create and photos you upload and take stay private. Your personal data, images, and AI models are stored securely, and our system is designed to protect your information from unauthorized access.',
  },
  {
    question: 'Can I upgrade or downgrade my subscription?',
    answer:
      "Yes, you can upgrade or downgrade your subscription at any time. Go to the top of SeductiveSnapAI when you are logged in and click Billing, you will be redirected to Stripe's billing portal where you can switch plans.",
  },
  {
    question: 'Can I cancel my subscription anytime?',
    answer:
      'You can cancel your subscription at any time. Go to the top of SeductiveSnapAI when you are logged in and click Billing, you will be redirected to Stripe\'s billing portal where you can download invoices, switch plans and cancel your subscription. <br><br>If you are not logged in, first login: go to the top of <a href="https://dreamsnapai.com">SeductiveSnapAI\'s frontpage</a> and enter your email in the email box, click the button to continue. Follow the link in the email you got and click Billing in the top right of that page. <br><br>If you can not login, no worries: click Billing on the top of <a href="https://dreamsnapAI.com">DreamSnapAI\'s frontpage</a> and enter the email you signed up with on Stripe\'s billing portal. Stripe will send you an email to login and then you can download invoices, switch plans and cancel your subscription there.',
  },
  {
    question: 'Can I get a refund?',
    answer:
      'Unfortunately, we cannot offer refunds as costs incurred for creating AI models and generating AI photos are extremely high. In turn, our upstream providers do not let us ask for refunds for the GPU processing time used to create your AI models and generate your AI photos. This would make it a loss making endeavor for us. During sign up you agree to withhold your right to refund for this reason. You can cancel any time though and your subscription ends.',
  },
];

export const pricingFaqItems: FAQItem[] = [
  {
    question: 'How do I get 6+ months free when signing up?',
    answer:
      'If you sign up for the yearly plan, the pricing is over 50% cheaper, which means 6+ months free compared to regular monthly pricing. Make sure to select yearly and you are saving a lot of money! This is our way of thanking you for your trust in our SeductiveSnapAI and lets us reinvest your money to improve the product.',
  },
  {
    question: 'Do I get access after paying?',
    answer:
      'Yes, immediately after payment you get an automated email with a login link. You can then create your first AI model and start taking AI photos. Creating a model usually takes ~30 minutes and then taking a photo takes ~30 seconds.',
  },
  {
    question: 'Is the payment service secure?',
    answer:
      'Yes, our payment service is secure. We use Stripe for financial processing, and we do not store any information about your card. Stripe ensures bank-level security standards.',
  },
  {
    question: 'Can I get a receipt or invoice?',
    answer: 'Yes, after payment you get a receipt and invoice.',
  },
  {
    question: 'Is my data secure? Do my photos and models remain private?',
    answer:
      'Yes! The models you create and photos you upload and take stay private. Your personal data, images, and AI models are stored securely, and our system is designed to protect your information from unauthorized access.',
  },
  {
    question: 'What methods of payment do you accept?',
    answer:
      'We accept payments via credit or debit cards, including MasterCard, VISA, American Express, as well as iDeal, SOFORT, Bancontact, Przelewy24, Giropay, EPS, GrabPay, AliPay, and many more. However, we do not accept PayPal or cryptocurrency and are not planning to.',
  },
  {
    question: 'Can I upgrade or downgrade my subscription?',
    answer:
      "Yes, you can upgrade or downgrade your subscription at any time. Go to the top of SeductiveSnapAI when you are logged in and click Billing, you will be redirected to Stripe's billing portal where you can switch plans.",
  },
  {
    question: 'Can I cancel my subscription anytime?',
    answer:
      "You can cancel your subscription at any time. Go to the top of SeductiveSnapAI when you are logged in and click Billing, you will be redirected to Stripe's billing portal where you can download invoices, switch plans and cancel your subscription.",
  },
  {
    question: 'Can I get a refund?',
    answer:
      'Unfortunately, we cannot offer refunds as costs incurred for creating AI models and generating AI photos are extremely high. In turn, our upstream providers do not let us ask for refunds for the GPU processing time used to create your AI models and generate your AI photos. This would make it a loss making endeavor for us. During sign up you agree to withhold your right to refund for this reason. You can cancel any time though and your subscription ends.',
  },
];
