import Header from '../components/Home/Header';
import Hero from '../components/Home/Hero/Hero';
import Main from '../components/Home/Main/Main';
import Features from '../components/Home/Features/Features';
import DetailFeature from '../components/Home/DetailFeature/DetailFeature';
import CreateFeature from '../components/Home/Features/CreateFeature';
import PhotorealisticFeature from '../components/Home/Features/PhotorealisticFeature';
import FAQ from '../components/Home/FAQ/FAQ';
import Pricing from '../components/Home/Pricing/Pricing';
import FooterBox from '../components/Home/FooterBox/FooterBox';
import Footer from '../components/Home/Footer/Footer';
import { ReactNode } from 'react';
import { homeFaqItems } from '../data/faq';
import DocumentTitle from '../utils/DocumentTitle';
import { useDashboard } from '../components/Dashboard/DashboardContext';

interface HomeProps {
  id?: number;
  title?: string;
  description?: ReactNode;
  documentTitle?: string;
}

export default function Home({ id, title, description, documentTitle }: HomeProps) {
  const { authState } = useDashboard();
  const showLogin = window.location.pathname === '/' || window.location.pathname === '/#';
  const titleClick = () => {
    if (authState.isAuthenticated) {
      window.location.href = '/dashboard';
    } else {
      window.location.href = '/#';
    }
  };
  return (
    <div>
      <DocumentTitle title={documentTitle || 'SeductiveSnapAI'} />
      <Header titleClick={titleClick} />
      <Hero id={id} title={title} description={description} showLogin={showLogin} />
      <Main />
      <Features />
      <DetailFeature />
      <CreateFeature />
      {/* <TryOnFeature /> */}
      <PhotorealisticFeature />
      {/* <VideoClipFeature /> */}
      <FAQ faqItems={homeFaqItems} />
      <Pricing header homePage />
      <FooterBox />
      <Footer />
    </div>
  );
}
