import TopFolderSelector from './TopFolderSelector';
import '../../styles/Dashboard/DashMainSection.css';
import SavedFolder from './MainContent/SavedFolder';
import CameraFolder from './MainContent/CameraFolder';
import ExamplesFolder from './MainContent/ExamplesFolder';
import { useDashboard } from './DashboardContext';

export default function MainContent() {
  const { activeFolder, setActiveFolder } = useDashboard();

  return (
    <div className="section main" tabIndex={1}>
      <TopFolderSelector activeFolder={activeFolder} setActiveFolder={setActiveFolder} />
      <div style={{ clear: 'both' }} />
      {activeFolder === 'saved' && <SavedFolder />}
      {activeFolder === 'camera' && <CameraFolder />}
      {activeFolder === 'examples' && <ExamplesFolder />}
    </div>
  );
}
