import '../../styles/Header.css';

interface HeaderProps {
  titleClick?: () => void;
}

export default function Header({ titleClick }: HeaderProps) {
  return (
    <div className="header">
      <div className="logo">
        <a tabIndex={-1} onClick={titleClick ? titleClick : undefined}>
          <h1 style={{ fontWeight: 300 }}>
            <style></style>
            <div className="logo-box" />
            <span style={{ fontWeight: 'bold' }}>SeductiveSnap</span>AI
          </h1>
        </a>
      </div>
    </div>
  );
}
