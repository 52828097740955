import '../../../styles/Features.css';

export default function PhotorealisticFeature() {
  return (
    <div>
      <div className="feature">
        <div className="feature-left">
          <h2 id="prompt">Design photorealistic scenes</h2>
          <p>
            Envision a romantic candlelit dinner, a sultry boudoir, or a seaside escapade.
            SeductiveSnapAI allows you to design any intimate scene you desire, from the uncommon to
            the extraordinary. Imagine conjuring a sizzling forest tryst under a starry night sky,
            or a steamy waterfall rendezvous, all within the comfort of your own home.
            <br />
            <br />
            Describe the scene you crave and our AI-powered model will generate a photorealistic
            image that mirrors your desires. Imagine the convenience and speed: each snap takes just
            seconds to create, leaving you with unlimited opportunities to explore your every
            fantasy. 🌃
          </p>
        </div>
        <div className="feature-right feature-3d">
          <div style={{ position: 'relative', paddingTop: '62.28373702422145%' }}>
            <video
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
              }}
              playsInline
              controls
            >
              <source src="videos/takePhoto.mp4" type="video/mp4" />
              {/* Fallback text if video fails to load */}
              <p>Your browser doesn't support this video</p>
            </video>
          </div>
        </div>
      </div>
      <div style={{ clear: 'both' }} />
      <br />
      <br />
      <br />
    </div>
  );
}
